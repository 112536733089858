.slick-slide {margin: 0}

.is-flex,
.main-blog__container,
.main-partners__container {
    display: flex;
}

.checkbox__span {border-radius: 15%}

.checkbox__span::after {left: 7px !important;}

.header-menu-link_active {color: #242729;border-bottom: 2px solid #fbbe07;}

a {
    text-decoration: none;
    color: #6d6d73;
}

.popup__cursor {
    cursor: pointer;
}

.header__flags {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.dropbtn {
    padding: 5px;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    width: 150px;
    right: -1px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(251, 190, 7, 0.5);
}

.dropbtn::after {
    content: "";
    height: 16px;
    position: absolute;
    right: 0;
    top: 7px;
    width: 16px;
}

.dropdown-content img {
    color: black;
    /* padding: 2px 0 2px 25px; */
    text-decoration: none;
    display: block;
    /* border: 1px solid #fbbe07;
    border-radius: 50%; */
}

.dropdown-content a:hover {background-color: white}

.dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 6px;
    /* border: 1px solid #fbbe07; */
    z-index: 10;
}

.header__flags-string {
    display: flex;
    flex-direction: row;
    background-color: white;
    margin-bottom: 6px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-bottom: 6px;
    border-bottom: 1px dotted #fbbe07;
}
.header__flags-string:last-child {
    margin-bottom: 0px;
    border-bottom: none;
    padding-bottom: 0px;
}

.phone-input {
    display: flex;
}

.phone-input div:first-child {
    height: 60px;
    line-height: 60px;
    padding-right: 10px;
    padding-left: 5px;
}

.phone-input div.danger {
    color: #E24;
}

.popup__finish {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.docs__items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 48px;
    max-width: 768px;
}

.docs__item {
    display: flex;
    /* width: 47%; */
    cursor: pointer;
    border: 1px solid #fbbe07;
    border-radius: 4px;
    max-width: 768px;
    min-width: 350px;
    padding: 12px;
    margin-bottom: 16px;
}

.docs__title {
    white-space: nowrap;
    font-family: "Segoe UI Semibold";
    height: 52px;
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.docs__item-desc {
    font-family: "Segoe UI";
    font-size: 14px;
    opacity: 0.6;
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (min-width: 768px) {

    .docs__title {
        white-space: nowrap;
        font-family: "Segoe UI Semibold";
        height: 52px;
        width: 100%;
    }

    .docs__item-desc {
        font-family: "Segoe UI";
        font-size: 14px;
        opacity: 0.6;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 768px) {

    .docs__title {
        white-space: nowrap;
        font-family: "Segoe UI Semibold";
        height: 52px;
    }

    .docs__item-desc {
        font-family: "Segoe UI";
        font-size: 14px;
        opacity: 0.6;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.docs__item-link {
    text-decoration: none;
}

.nav__bar-wrapper {
    border: none;
    background-color: transparent;
    width: 21px;
    height: 22px;
    padding: 0;
}

.mobile-header-logo {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobile-header-logo div {
    width: 100px;
}

.swiper-horizontal > .swiper-wrapper{
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }

.flag__wrapper {
    width: 24px;
    height: 24px;
    border: 1px solid #fbbe07;
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.flag__wrapper_ru {
    background-image: url('./img/language/ru.svg');
}

.flag__wrapper_tj {
    background-image: url('./img/language/tj.svg');
}

.flag__wrapper_en {
    background-image: url('./img/language/en.svg');
}

.corp__banner {
    margin-bottom: 32px;
}

.activation__wrapper {
    width: 350px;
    margin: 0 auto;
}

.activation__input {
    margin: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
    font-size: 1rem;
    line-height: 1.5rem;
    height: 2.875rem;
    padding: 8px 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    resize: none;
    outline: 0;
    color: #242729;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}

.header-right-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.open_hide {
    margin: 0;
    height: 2.875rem;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 50px;
    font-weight: 600;
    padding: 8px 8px;
    background-color: #fbbe07;
    color: #fff;
    text-align: center;
    border: 1px solid #fbbe07;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
}

.open_hide img {
    margin: 0 auto;
}

.open_hide:hover {
    transition: .3s;
    background: #ffd453;
    box-shadow: 0 0 10px rgba(251, 190, 7, 0.5);
}

.password__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
}

.big_logo {
    width: 400px !important;
    height: 60px !important;
    margin-bottom: 12px;
}

.header__flags_mob {
    display: flex;
    flex-direction: row;
    padding: 0 12px;
    align-items: center;
    justify-content: flex-end;
}

.header__flags_case_mob {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 210px;
    padding: 0 16px;
}
.header__flags-string_mob {
    display: flex;
    flex-direction: row;
    padding: 4px;

}

.select-lang__text {
    width: 120px;
    text-align: right;
}

.active-lang {
    border: 1px solid #fbbe07;
    border-radius: 4px;
}
.alert__text {
    font-size: 12px;
    margin-top: 10px;
}
@media (min-width: 1340px) {
    .alert__text {
        font-size: 16px;
        margin-top: 0;
    }
}
.checkbox__box {
    margin-top: 30px;
    margin-bottom: 30px;
}
.step {
    border: 2px solid #f6f6f6;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.tabs__btn {
    border: 0;
    background: transparent;
    color: #e0e0e0;
    font-size: 18px;
    position: relative;
    padding-right: 34px;
    padding-left: 4px;
    cursor: default;
}
.tabs__btn::after {
    content: "";
    position: absolute;
    top: 52%;
    right: 0;
    width: 30px;
    height: 2px;
    background-color: #e0e0e0;
    display: block;
}
.tabs__btn:last-child::after {
    display: none;
}
.tabs__btn_active {
    color: #fbbe07;
    opacity: 1;
    font-weight: 600;
    font-size: 22px;
}
.tabs__btn_finished {
    color: #ffd453;
    font-weight: 600;
    cursor: pointer;
}
.promo {
    width: 100%;
    margin-bottom: 24px;
}

.promo__subtitle {
    font-size: 14px;
    line-height: 19px;
    color: #242729;
    opacity: 0.7;
    margin-bottom: 12px;
}
.promo__input {
    width: 100%;
}
.input__promo {
    display: flex;
}
.promo__btn {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
}
@media (min-width: 1340px) {
    .promo {
        max-width: 300px;
    }
}
.travel-order__summ-box {
    margin-top: 32px;
    margin-bottom: 32px;
}
.travel-order__summ {
    font-size: 18px;
    margin-bottom: 32px;
    margin-top: 6px;
    padding-bottom: 16px;
    color: #242729;
    border-bottom: 2px solid #f6f6f6;
}
.travel-order__summ-item {
    font-size: 14px;
    line-height: 16px;
    color: #6d6d73;
    opacity: 0.7;
    padding-left: 12px;
    margin-bottom: 6px;
    margin-top: 6px;
}
@media (min-width: 1340px) {
    .travel-order__total {
        color: #242729;
    }
}
.travel-order__persons {
    font-size: 18px;
    margin-top: 42px;
    margin-bottom: 0px;
}
.travel-order__subtitle {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
}
.subtitle {
    margin-bottom: 0;
}
.add-person__button {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #fbbe07;
    border-bottom: 1px dashed;
    margin-left: auto;
    margin-bottom: 32px;
    text-align: end;
    width: fit-content;
    margin-top: 16px;
}
.travel-one__person {
    margin-bottom: 12px;
}
.change__button {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    color: #fbbe07;
    border-bottom: 1px dashed;
}
.change__button:hover {
    color: #ffd453;
}

.travel-step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    border: 2px solid #f6f6f6;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;
}
.travel__step-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.step-info__text {
    margin-right: 6px;
}
.step-info__text-accent {
    font-weight: 600;
}
.step-button {
    border: 0;
}
.travel-order__checkbox-mtop {
    margin-top: 16px;
    font-size: 14px;
    margin-bottom: 24px;
}
.travel-order__checkbox-mtop .checkbox__text {
    font-size: 14px;
}
.copy__button {
    margin-top: 16px;
    width: max-content;
}
@media (min-width: 1340px) {
    .copy__button {
        margin-top: 8px;
    }
}
@media (min-width: 768px) {
    .travel-step {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    .step-info__text {
        max-width: 70%;
    }

}
@media (min-width: 1340px) {
    .travel__step-info {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }
}
.insured-person__title {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
    }
@media (min-width: 1340px) {
    .insured-person__title {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}
.travel-one__option-row {
    margin-bottom: 30px;
}
.row-center {
    align-items: center;
}
.select__field {
    margin-top: 16px;
}
.select__list-travel {
    margin-top: 18px;
    width: 100%;
    overflow-y: hidden;
    border: 2px solid #e4e4e4;
}
@media (min-width: 1340px) {
    .select__list-travel {
        width: 40%;
    }
}
.travel__select-summ {
    overflow-y: hidden;
    border: 2px solid #e4e4e4;
}
.travel-option__select-item {
    font-size: 13px;
    line-height: 20px;
    color: #fbbe07;
    padding: 8px;
    display: flex;
    justify-content: flex-end;
}
.select__item-travel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: auto;
    border-bottom: 2px solid #f6f6f6;
}
.select__item-travel:hover {
    background-color: #fff;
}
.select__counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.counter-button {
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #fbbe07;
    background-color: transparent;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    line-height: 1;
    padding: 0;
    padding-bottom: 4px;
}
.counter__value {
    margin-left: 12px;
    margin-right: 12px;
}
.counter__decrease-inactive {
    border: 1px solid #6d6d73;
    color: #6d6d73;
    opacity: 0.5;
    cursor: auto;
}

.travel-currency__box {
    height: 56px;
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    margin-top: 32px;
}
@media (min-width: 1340px) {
    .travel-currency__wrap {
        margin-left: 46px;
        margin-top: 0px;
        width: 100%;
    }
}
.currency__item {
    width: 100%;
    height: 44px;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    border-radius: 4px;
    font-size: 15px;
    line-height: 1.2;
}
.currency__item_active {
    background-color: #fff;
    box-shadow: 0 2px 3px rgb(0 0 0 / 15%);
}
.currency__item + .currency__item {
    margin-left: 6px;
}
.travel-type__box {
    margin-left: 0;
    margin-bottom: 26px;
    width: 100%;
}
.travel-type__wrapp {
    margin-bottom: 46px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.travel-type__hint {
    font-size: 13px;
    position: relative;
}

.travel-option__title-box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    padding: 6px 0;
}
.travel-option {
    width: 100%;
    border: 2px solid #f6f6f6;
    border-radius: 4px;
    color: #6d6d73;
    /* background-color: #fff; */
    padding: 12px 16px;
    position: relative;
    transition: all 0.3s;
}
.travel-option:hover {
    transform: scale(1.02);
    z-index: 3;
}
.travel-option__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.travel-option__hint-icon {
    position: absolute;
    top: -16px;
    left: 10px;
    padding: 4px;
    background-color: #fff;
    width: 22px;
    height: 22px;
}
.travel-option__hint-icon {
    color: #fbbe07;
    font-weight: 600;
    text-align: center;
}
.travel-option__hint-content {
    color: #242729;
    font-weight: 400;
    font-size: 13px;
}
.travel-option__checked {
    color: #242729;
    border: 1px solid #fbbe07;
}
.travel-option__checked .travel-option__text {
    opacity: 1;
}
.travel-option__checked .travel-option__price {
    opacity: 1;
}
.travel-option__box {

    background-size: 36px auto;
    background-repeat: no-repeat;
    background-position: 14px center;
    position: relative;
    background-image: none;
}
@media screen and (min-device-width: 1340px) {
    .travel-option__box {

        background-size: 36px auto;
        background-repeat: no-repeat;
        background-position: 14px center;
        position: relative;
    }
    .travel-pl {
        padding-left: 46px;
    }
    .coronavirus {
    background-image: url(./img/coronavirus-01.svg);
    }
    .airbus {
        background-image: url(./img/airbus-01.svg);
    }
    .baggage {
        background-image: url(./img/baggage-01.svg);
    }
    .house {
        background-image: url(./img/home-01.svg);
    }
    .accident {
        background-image: url(./img/accident-01.svg);
    }
    .crush {
        background-image: url(./img/carscrush-01.svg);
    }
    .pregnance {
        background-image: url(./img/preg-01.svg);
    }
    .sun {
        background-image: url(./img/sun-01.svg);
    }
    .alco {
        background-image: url(./img/alcohol-01.svg);
    }
    .sea {
        background-image: url(./img/sea-01.svg);
    }
    .bag {
        background-image: url(./img/bag-01.svg);
    }
    .pdtl {
        background-image: url(./img/pdtl-01.svg);
    }
    .ugon {
        background-image: url(./img/ugon-01.svg);
    }
    .usherb {
        background-image: url(./img/usherb-01.svg);
    }
    .bez-gai {
        background-image: url(./img/bez-gai-01.svg);
    }
    .hospitalization {
        background-image: url(./img/hospitalization-01.svg);
    }
    .green {
        background-image: url(./img/green.svg);
    }
    .compensation {
      background-image: url(./img/compensation-01.svg);
    }
    .calculate {
        background-image: url(./img/calculate-01.svg);
    }
    .stoa {
        background-image: url(./img/stoa-01.svg);
    }
    .expertise {
        background-image: url(./img/expertise-01.svg);
    }
}

.checkbox__price {
    display: block;
    text-align: right;
}
.travel-option__text {
    font-size: 14px;
    line-height: 16px;
    opacity: 0.5;
}
.travel-option__span {
    top: 14px;
    left: calc(100% - 38px);
}
.travel-option__price {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: #fbbe07;
    opacity: 0.5;
    margin-left: 16px;
    margin-right: 36px;
    text-align: right;
    width: max-content;
    flex-wrap: nowrap;
}
@media (min-width: 1340px) {
    .travel-option__text {
        font-size: 16px;
        line-height: 20px;
    }
    .travel-option__price {
        font-size: 16px;
        line-height: 20px;
    }
    .travel-option__span {
        top: 17px;
    }
}
.select__travel-option {
    width: max-content;
    max-width: 110px;
}

.select__list-option {
    top: 7px;
    overflow-y: hidden;
    /* z-index: 21; */
}
.travel-option__select-current {
    border: none;
    background-color: transparent;
    color: #fbbe07;
    font-size: 13px;
    line-height: 18px;
    height: min-content;
    padding: 0 6px;
    padding-right: 24px;
    margin-top: 6px;
    font-weight: 600;
    opacity: 0.5;
}
.travel-option__checked .travel-option__select-current {
    opacity: 1;
}
.select__text-value {
    font-weight: 400;
}
.travel-option__currency {
    text-transform: uppercase;
    margin-left: 6px;
}
.travel-option .select__current::after {
    top: 4px;
    right: 6px;
}

.travel-option__text-box {
    display: flex;
    flex-direction: column;
    width: max-content;
    max-width: 55%;
}
.travel-option__name {
    display: flex;
}

.gender__row {
    align-items: flex-end;
}
.gender-box {
    width: 100%;
    margin-left: 0;
}
/* @media (min-width: 768px) {
    .gender-box {
        width: 48%;
    }
} */
.holder-checkbox {
    margin-top: 20px;
    margin-bottom: 32px;
}
.holder-checkbox__text {
    font-family: "Segoe UI";
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #242729;
    opacity: 1;
    margin-top: 20px;
    margin-bottom: 13px;
    padding-top: 2px;
    padding-left: 34px;
}
.estate-adress__block {
    margin-bottom: 32px;
}
.rules__check {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 32px;
}
.rules__check-error .checkbox__text {
    color: #eb5757;
}
.rules__check-error .checkbox__span {
    border: 1px solid #eb5757;
}
.privacy__rules {
    cursor: pointer;
    color: #fbbe07;
}
.privacy__rules:hover {
    color: #ffd453;
}
.insurance__summ {
    border: 0;
    margin-top: 0;
}
.summ__text {
    font-size: 14px;
    line-height: 19px;
    color: #242729;
    opacity: 0.7;
    margin-bottom: 12px;
    font-weight: normal;
}
.aside__hint {
    font-family: "Segoe UI";
    font-size: 11px;
    line-height: 15px;
    color: #242729;
    opacity: 0.6;
    margin-top: 6px;
    margin-bottom: 12px;
}
.aside__option {
    font-family: "Segoe UI Semibold";
    font-size: 13px;
    line-height: 16px;
    color: #242729;
    opacity: 1;
    /* margin-top: 6px; */
    /* margin-bottom: 6px; */
    margin-right: 6px;
    display: inline-block;
}
.contacts__name {
    font-family: "Segoe UI";
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #242729;
    opacity: 0.8;
    margin-top: 20px;
    margin-bottom: 13px;
}
.contacts__data {
    margin-bottom: 6px;
    font-family: "Segoe UI";
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #242729;
}
.contacts__name_wrap {
    margin-bottom: 10px;
}
.contacts__info {
    margin-bottom: 6px;
    font-family: "Segoe UI Semibold";
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #242729;
    margin-bottom: 12px;
}
.contacts__item {
    width: 100%;
}
.contacts__info_number {
    font-size: 16px;
    padding-left: 12px;
}
.contacts__info_box {
    margin-top: 24px;
    margin-bottom: 32px;
}
.step-button:disabled {
    background-color: #f6f6f6;
    cursor: default;
    box-shadow: none;
    color: #e0e0e0;
}
.sticky-block {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
}
.select__travel-order .travel-currency__box {
    margin-top: 0;
}
.travel-type__hint-text {
    margin-right: 6px;
}
.travel-type__hint-link {
    color: #fbbe07;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #fbbe07;
    padding: 0;
    margin-bottom: 12px;
}
.travel-type__hint-link:hover {
    color: #ffd453;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #ffd453;
}
body.noscroll {
    overflow: hidden;
}
.travel-type__hint-popup {
    display: none;
    width: 100vw;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 100;
    margin: 0;
}
.travel-type__hint-popup.travel-popup-active {
    display: block;
}
.travel-type__hint-info {
    position: relative;
    padding: 12px;
    background-color: #fff;
    border-radius: 4px;
    margin: 0 auto;
    width: 98%;
    margin-top: 1vh;
    font-size: 14px;
}
@media (min-width: 448px) {
    .travel-type__hint-info {
        width: 75%;
    }
}
@media (min-width: 768px) {
    .travel-type__hint-info {
        width: 55%;
        margin-top: 3vh;
    }
}
@media (min-width: 998px) {
    .travel-type__hint-info {
        width: 45%;
        margin-top: 5vh;
    }
}
@media (min-width: 1340px) {
    .travel-type__hint-info {
        width: 40%;
    }
}
.travel-type__hint-head {
    display: flex;
    justify-content: space-between;
}
.travel-type__hint-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 16px;
}
.travel-type__hint-close {
    background-color: transparent;
    border: 0;
    width: 20px;
    height: 20px;
    position: relative;
}
.travel-type__hint-close span {
    width: 20px;
    height: 1px;
    position: absolute;
    top: 10px;
    right: 0px;
    background-color: #D9DBE1;
    transition: all 0.5s;
}
.travel-type__hint-close span:nth-of-type(1) {
    transform: rotate(45deg);
}

.travel-type__hint-close span:nth-of-type(2) {
    transform: rotate(-45deg);
}
.travel-type__hint-list {
    margin-top: 6px;
}
.travel-type__hint-item {
    list-style-type: disc;
    margin-bottom: 6px;
}
.travel-type__hint-item::marker {
    color: #ffd453;
}

.travel-option__description-button {
    color: #fbbe07;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #fbbe07;
    padding: 0;
    margin-bottom: 12px;
}
.travel-option__description-button:hover {
    color: #ffd453;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #ffd453;
}
.travel-option__description {
    font-size: 13px;
    margin-bottom: 24px;
}
.travel-one__value-mb {
    margin-bottom: 12px;
}
.aside-option__name {
    margin-bottom: 6px;
}
.aside-popup__title {
    margin-top: 6px;
    margin-bottom: 8px;
}
.aside-popup__item {
    font-size: 12px;
    line-height: 14px;
}
@media (min-width: 1340px) {
    .select__auto-order {
        margin-right: 46px;
    }
    .select__auto-order:last-child {
        margin-right: 0;
    }
}
.auto-rules__check {
    margin-top: 24px;
    margin-bottom: 24px;
}
@media (min-width: 1340px) {
    .auto-rules__check {
        margin-top: 10px;
    }
}
.auto-checkbox__text {
    margin: 0;
    font-size: 16px;
}
.auto__currency {
    margin-left: 6px;
}
.auto__monthly {
    font-size: 12px;
    opacity: 0.6;
}
.auto__value {
    margin-bottom: 0;
}
/* .auto__name {
    margin-bottom: 10px;
} */
.auto-main__block {
    margin-bottom: 24px;
}
.auto__description-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
}
.auto__total_main {
    margin-top: 24px;
}
.auto__year-text {
    margin-left: 6px;
}
.auto__option-row {
    margin-top: 26px;
    margin-bottom: 10px;
}
.auto__subtitle {
    position: relative;
    opacity: 0.6;
    /* width: 210px; */
    min-width: max-content;
    font-size: 20px;
    font-weight: 600;
}
.auto__subtitle-box {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}
.auto__subtitle-decor {
    height: 1px;
    width: calc(100% - 20px);
    margin-top: 6px;
    margin-left: 12px;
    background-color: #e5e5e5;

}
.auto__name {
    display: flex;
    flex-direction: column;
}
.auto__extra-text {
    font-size: 13px;
}
@media (min-width: 1340px) {
    .auto-option__box {
        /* background-image: url(./img/car-33.png);
        background-size: 200px auto;
        background-repeat: no-repeat;
        background-position: center center; */
        background-size: 36px auto;
        background-repeat: no-repeat;
        background-position: 14px center;
        position: relative;
    }
    .auto-option__box-top {
      background-position: 14px 26px;
    }
}
.select__text-desc {
    font-size: 13px;
    line-height: 1.1;
    opacity: 0.6;
    margin-top: 6px;
}
.select__search {
    outline: none;
    padding: 4px;
    margin: 4px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    width: calc(100% - 18px);
    background-image: url(./img/search_icon.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right 6px center;
}
.auto-info__text {
    max-width: 100%;
}
@media (min-width: 1340px) {
    .auto__input {
        margin-right: 46px;
    }
    .auto__input:last-child {
        margin-right: 0;
    }
}
.auto-option__wrap {
    margin-bottom: 24px;
}
.auto-label__margin {
    margin-top: 0;
}
.auto-rules__multi {
    margin-left: 16px;
}
@media (min-width: 1340px) {
    .auto-rules__multi {
        margin-right: 96px;
    }
}
.driver-card__wrap {
    padding: 24px 16px;
    border: 2px solid #f6f6f6;
    border-radius: 4px;
    position: relative;
    margin-top: 36px;
    margin-bottom: 24px;
}
.insurer-card__wrap {
    padding-top: 0;
}
.auto-row__not-margin {
    margin-bottom: 0;
}
.auto__copy-button {
    display: block;
    margin-left: auto;
    text-align: end;
    width: max-content;
}
.auto__add-button {
    margin-bottom: 0;

}
.driver-button {
    margin-top: 12px;
    margin-bottom: 16px;
}
.drivers-block__hide {
    display: none;
}
.driver-block__number {
    font-size: 20px;
    line-height: 0.9;
    padding: 6px;
    /* border: 2px solid #f6f6f6;
    border-radius: 4px; */
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -24px;
    left: 16px;
    background-color: #fff;
    color: #adadb1;
}
.auto-control__block {
    border: 2px solid #f6f6f6;
    border-radius: 4px;
    padding: 16px;
    margin-top: 24px;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.auto-control__block span {
    margin-right: 6px;
}
.auto-control__block span:first-child {
    margin-left: 0;
}
.auto-control__name {
    font-family: 'Segoe UI Semibold';
}
.auto-control__type {
    font-family: 'Segoe UI Semibold';
}
.auto-control__number {
    font-family: 'Segoe UI Semibold';
}
.auto-control__value {
    font-family: 'Segoe UI Semibold';
}
.label-valid {
    padding-left: 20px;
    position: relative;
}
.label-valid::before {
    content: "";
    background-image: url(./img/check.svg);
    width: 14px;
    height: 14px;
    position: absolute;
    top: 2px;
    left: 0;

}
.warning-fields {
    position: relative;
    font-size: 12px;
    color: #6d6d73;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: max-content;
    padding-left: 20px;
    margin-bottom: 16px;
}
.warning-fields::before {
    content: "i";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 1px;
    left: 0;
    border-radius: 50%;
    border: 1px solid #6d6d73;
    display: flex;
    align-items: center;
    justify-content: center;
}
.warning-fields__button {
    color: #fbbe07;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid transparent;
    padding: 0;
    margin-bottom: 12px;
    margin-left: 4px;
    cursor: pointer;
}
.warning-fields__button:hover {
    color: #ffd453;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #ffd453;
    cursor: pointer;
}
.installment-block {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    width: 100%;
    margin-bottom: 36px;
    margin-top: 46px;
}
@media (min-width: 480px) {
    .installment-block {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
    }
}
@media (min-width: 1340px) {
    .installment-block {
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 24px;
        margin-top: 0;
    }
}
.installment-block__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: 2px solid #f6f6f6;
    border-radius: 4px;
    padding: 16px;
}
.installment-block__date {
    padding-top: 12px;
    padding-bottom: 6px;
}
.installment-block__summ {
    font-family: 'Segoe UI Semibold';
}
.installment-block__percent {
    position: absolute;
    top: -36px;
    left: 50%;
    transform: translate(-50%);
    font-family: 'Segoe UI Semibold';
    font-size: 36px;
    color: #fbbe07;
    border-radius: 4px;
    padding: 6px;
    background-color: #fff;
}

.payments {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(43%, 1fr));
    gap: 60px;
    row-gap: 16px;
}
@media (min-width: 768px) {
    .payments {
        gap: 60px;
        row-gap: 30px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}
.payments__input[type=radio]:checked, .payments__input[type=radio]:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
    background-color: #fff;
}
.payments__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.payments__image {
    width: 90px;
    height: 50px;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 10px;
}
@media (min-width: 768px) {
    .payments__image {
        width: 110px;
        height: 70px;
        margin: 0 auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-bottom: 12px;
    }
}
.payments__image--vasl {
    background-image: url(./img/vasl.svg);
}
.payments__image--corti {
    background-image: url(./img/corti.svg);
}
.payments__image--card {
    background-image: url(./img/visa-mc.png);
}
.payments__image--dccity {
    background-image: url(./img/dc_logo.svg);
}
.payments__image--qrcode {
    background-image: url(./img/qr_logo.svg);
}
.payments__image--payler {
    background-image: url(./img/payler_logo.svg);
}

.payments__input:checked + label,
.payments__input:not(:checked) + label {
    position: relative;
    display: inline-block;
    padding: 12px;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    margin: 0 auto;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
    color: #242729;
    font-weight: 600;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}
@media (min-width: 768px) {
    .payments__input:checked + label,
    .payments__input:not(:checked) + label {
        padding: 20px;
        width: 100%;
        line-height: 18px;
    }
}
.payments__input:not(:checked) + label {
    border: 2px solid #f6f6f6;
}

.payments__input:checked + label {
    border: 2px solid #fbbe07;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.payments__input:not(:checked) + label:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.travel-type__payments {
    padding: 30px;
    width: 65%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
}
.payments__subtitle {
    font-size: 18px;
    margin-bottom: 10px;
}
.payments__submit {
	padding: 12px;
    align-self: center;
    width: 100%;
    font-size: 20px;
    margin-top: 20px;
}
@media (min-width: 768px) {
    .payments__submit {
        width: 30%;
    }
}
.popup__close {
	outline: none;
	font-size: 24px;
	line-height: 1;
	position: absolute;
	right: 8px;
	top: 0;
	padding: 0;
	color: #6d6d73;
    border: none;
    background-color: #fff;
}

.auto-product__container {
  box-sizing: border-box;
  min-width: 320px;
  padding: 0 16px;
}

@media (min-width: 768px) {
  .auto-product__container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

@media (min-width: 1340px) {
  .auto-product__container {
    width: 1280px;
  }
}

@media (min-width: 1440px) {
  .travel-order__container {
    width: 1280px;
  }
}
.auto-product__title {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 24px;
  color: #242729;
}
@media (min-width: 1340px) {
  .auto-product__title {
    margin-bottom: 45px;
    font-size: 32px;
    line-height: 43px;
    font-family: "Segoe UI Semibold";
  }
}
.auto-card__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  padding: 10px 0 13px;
  cursor: pointer;
  background: #fbbe07;
  box-shadow: 0 0 16px rgba(104, 104, 104, 0.1);
  color: #fff;
}

.auto-card__button:hover {
  transition: .3s;
  background: #ffd453;
  box-shadow: 0 0 10px rgba(251, 190, 7, 0.5);
}

.auto-product__box {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@media (min-width: 768px) {
  .auto-product__box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (min-width: 1340px) {
  .auto-product__box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
}

.auto-card {
  display: grid;
  grid-template-columns: 1fr;
  /* Не убирать grid-template-rows - поедет карточка */
  grid-template-rows: 1fr 2fr;
  gap: 20px;
  min-height: 570px;
  background: #ffffff;
  border: 2px solid #f6f6f6;
  box-shadow: 0 0 16px rgba(104, 104, 104, 0.1);
  border-radius: 4px;
  padding: 30px;
  cursor: pointer;
}
.auto-card:hover {
  transition: .3s;
  border: 2px solid rgba(251, 190, 7, 0.3);
  box-shadow: 0 0 10px rgba(251, 190, 7, 0.4);
}

.auto-card:active {
  border: 2px solid #fbbe07;
}

.auto-card__image {
  width: 100%;
  height: 180px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}
/* TODO: Картинки временные */
.auto-card__image-1 {
  background-image: url('./img/1.jpg');
}

.auto-card__image-2 {
  background-image: url('./img/2.jpg');
}

.auto-card__image-3 {
  background-image: url('./img/3.jpg');
}

.auto-card__image-4 {
  background-image: url('./img/4.jpg');
}

.auto-card__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.auto-card__title {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  font-family: "Segoe UI Semibold";
  font-size: 18px;
  line-height: 40px;
  margin-bottom: 16px;
}

.auto-card__price {
  justify-self: flex-end;
}

.auto-card__list {
  margin: 12px;
}

.auto-card__item {
  padding-left: 20px;
  position: relative;
}

.auto-card__item::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #242729;
  opacity: .6;
  left: 9px;
  top: 11px;
}

.auto-card__desc {
  margin-bottom: auto;
}
.auto-card__button {
  margin-top: 24px;
}
.auto-comments {
  margin-top: 30px;
  color: #6d6d73;
}
.auto-comments__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Segoe UI Semibold";
  font-size: 16px;
  line-height: 28px;
  padding-right: 3px;
}

.auto-comments__btn {
  width: 10px;
  height: 10px;
  padding: 0;
  background: transparent;
  border: none;
  border-right: 2px solid #6d6d73;
  border-bottom: 2px solid #6d6d73;
  transform: rotate(45deg);
  margin-right: 6px;
  margin-top: 0;
}

.auto-comments.open >.auto-comments__head >.auto-comments__btn {
  transition: .3s;
  transform: rotate(-135deg);
  margin-right: 0;
  margin-top: 10px;
}

.auto-comments__list {
  display: none;
}

.auto-comments.open>.auto-comments__list {
  display: block;
}

.auto-comment__list {
  margin: 12px;
}

.auto-comment__item {
  padding-left: 20px;
  position: relative;
  width: max-content;
  max-width: 96%;
  font-size: 14px;
}

@media (min-width: 768px) {
  .auto-comment__item {
    max-width: 80%;
  }
}

@media (min-width: 1340px) {
  .auto-comment__item {
    max-width: 70%;
  }
}
.auto-comment__item::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fbbe07;
  opacity: .6;
  left: 9px;
  top: 11px;
}

.auto-comment__hint-icon {
  position: absolute;
  top: -4px;
  right: -34px;
  padding: 4px;
  background-color: #fff;
  width: 28px;
  height: 4px;
  color: #fbbe07;
  font-weight: 600;
  text-align: center;
  opacity: .6;
  border-radius: 100%;
  border: 1px solid #fbbe07;
  padding-bottom: 24px;
}

.auto-comment__hint-content {
  color: #242729;
  font-weight: 400;
  font-size: 13px;
}

.auto-option__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-left: 46px;
  text-transform: uppercase;
  color: #fbbe07;
  font-size: 13px;
  border-bottom: 1px solid #f6f6f6;
  padding-top: 8px;
  padding-bottom: 8px;
}
.auto-option__list:last-child {
  border-bottom: none;
}
.auto-option__price {
  position: relative;
}
.auto-option__check {
  position: relative;
}
.auto-option__span {
  top: 0px;
  left: 64px;
}
.auto-option__radio {
  border-radius: 100%;
}
.ponimayu__title {
    font-size: 24px;
    line-height: 32px;
    font-family: "Segoe UI Semibold";
    color: #242729;
    margin-top: 16px;
    margin-bottom: 24px;
}
.ponimayu__text {
    /* display: inline-block; */
    font-size: 14px;
    line-height: 20px;
    font-family: "Segoe UI";
    color: #242729;
    margin-top: 6px;
    margin-bottom: 12px;
}
.ponimayu__text:last-child {
  margin-bottom: 40px;
}
.ponimayu__text--bold {
  font-family: "Segoe UI Semibold"
}
.ponimayu__text a {
  display: inline-block;
  color: #242729;
  padding-left: 6px;
}
.ponimayu__img {
  width: 100%;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ponimayu__img--1 {
  background-image: url(./img/pic1.JPG);
}
.ponimayu__img--2 {
  background-image: url(./img/pic2.JPG);
}
.ponimayu__img--3 {
  background-image: url(./img/pic3.JPG);
}
@media (min-width: 768px) {
  .ponimayu__img {
      min-height: 400px;
      margin-top: 24px;
      margin-bottom: 24px;
  }
}
@media (min-width: 1340px) {
    .ponimayu__title {
        font-size: 32px;
        line-height: 43px;
        margin-bottom: 40px;
        margin-top: 24px;
    }
    .ponimayu__text {
        font-size: 16px;
        line-height: 21px;
        margin-top: 10px;
        margin-bottom: 16px;
    }
    .ponimayu__img {
        min-height: 500px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

@media (min-width: 1340px) {
    .travel-order__row-wide {
        display: flex;
        gap: 4%;
    }
    .order__inner-row {
        display: flex;
        width: 48%;
    }
    .travel-order__row-wide .input {
        width: 48%;
    }
    .order__inner-row .select {
        width: 68%;
    }
    .order__inner-row .input {
        width: 28%;
    }
}
@media (max-width: 1339px) {
    .order__inner-row {
        display: flex;
        width: 100%;
        gap: 4%;
    }
    .travel-order__row-wide {
        flex-direction: column;
    }
    .travel-order__row-wide .input {
        width: 100%;
    }
    .order__inner-row .select {
        width: 48%;
    }
    .order__inner-row .input {
        width: 48%;
    }
}
@media (max-width: 540px) {
    .order__inner-row {
        flex-direction: column;
    }

    .order__inner-row .select {
        width: 100%;
    }
    .order__inner-row .input {
        width: 100%;
    }
}
